import { OeReportType, OeReportSubType } from "./enum";
import { useOeReport } from "./useOeReport";
import { useRouter } from "./useRouter";
const { oeReport } = useOeReport();
const { router } = useRouter();

/** 自定义滑动指令 */
let _touchMove = false;
const _touchParams: AnyObject = { x: 0, y: 0, x1: 0, y1: 0 };
const _scrollMap: AnyObject = {};
export const _first_inter_show = { shown: !!sessionStorage.getItem("HAA_FIRST_INTER"), spaceKey: "" };

const handleTouchStart = (el: any) => {
  return function (e: any) {
    const { clientX, clientY } = e.touches[0];
    _touchParams.x = clientX;
    _touchParams.y = clientY;
    _touchMove = false;
  };
};
const handleTouchMove = (el: any) => {
  return function (e: any) {
    const { clientX, clientY } = e.touches[0];
    _touchParams.x1 = clientX;
    _touchParams.y1 = clientY;
    _touchMove = true;
  };
};
const handleTouchEnd = (el: any) => {
  return function (e: any) {
    if (_touchMove) {
      const pageName = router.routerInstance.currentRoute.value.name as string;
      const scrollRate = el.clientHeight >= el.scrollHeight ? "0" : Math.floor((el.scrollTop / (el.scrollHeight - el.clientHeight)) * 100) + "";
      const deepCount = sessionStorage.getItem("HAA_PAGE_DEEP");
      const countNumber = deepCount ? deepCount : "1";
      const interShown = _first_inter_show.shown ? "1" : "0";
      if (scrollRate == "0") {
        const { x, y, x1, y1 } = _touchParams;
        const distance = Math.round(Math.sqrt(Math.pow(Math.abs(x1 - x), 2) + Math.pow(Math.abs(y1 - y), 2)));
        let angle = Math.round((Math.atan2(y1 - y, x1 - x) * 180) / Math.PI) + 90;
        if (angle < 0) angle += 360;
        oeReport(OeReportType.Action, OeReportSubType.PageTouchMoved, pageName, scrollRate, countNumber, distance, angle, interShown);
      } else {
        oeReport(OeReportType.Action, OeReportSubType.PageTouchMoved, pageName, scrollRate, countNumber, undefined, undefined, interShown);
      }
    }
    _touchParams.x = 0;
    _touchParams.y = 0;
    _touchParams.x1 = 0;
    _touchParams.y1 = 0;
  };
};

const handleScroll = (el: any) => {
  let timer: any = null;
  return function (event: any) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      const pageName = router.routerInstance.currentRoute.value.name as string;
      if (!_scrollMap[pageName]) {
        _scrollMap[pageName] = { scrollTop: el.scrollTop, reportTimes: 0 };
      }
      if (Math.abs(el.scrollTop - _scrollMap[pageName].scrollTop) > 50 && _scrollMap[pageName].reportTimes < 100) {
        const scrollRate = el.clientHeight >= el.scrollHeight ? "0" : Math.floor((el.scrollTop / (el.scrollHeight - el.clientHeight)) * 100) + "";
        const deepCount = sessionStorage.getItem("HAA_PAGE_DEEP");
        const countNumber = deepCount ? deepCount : "1";
        // oeReport(OeReportType.Action, OeReportSubType.ActionCollect, pageName, "PageSlide", scrollRate, countNumber);
        oeReport(OeReportType.Action, OeReportSubType.PageTouchMoved, pageName, "PageSlide", scrollRate, countNumber);
        _scrollMap[pageName].scrollTop = el.scrollTop;
        _scrollMap[pageName].reportTimes += 1;
      }
    }, 300);
  };
};
const vSlide = {
  created(el: any, binding: any, vnode: any, prevVnode: any) {
    el._touchstart_handler = handleTouchStart(el);
    el._touchmove_handler = handleTouchMove(el);
    el._touchend_handler = handleTouchEnd(el);
    el._scroll_handler = handleScroll(el);
    el.addEventListener("touchstart", el._touchstart_handler);
    el.addEventListener("touchmove", el._touchmove_handler);
    el.addEventListener("touchend", el._touchend_handler);
    el.addEventListener("scroll", el._scroll_handler);
  },
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount(el: any) {
    el.removeEventListener("touchstart", el._touchstart_handler);
    el.removeEventListener("touchmove", el._touchmove_handler);
    el.removeEventListener("touchend", el._touchend_handler);
    el.removeEventListener("scroll", el._scroll_handler);
  },
  unmounted() {}
};

/** 自定义点击指令 */
const handleClick = (arg: string, value: any, modifiers: AnyObject) => {
  return function (e: any) {
    const pageName = router.routerInstance.currentRoute.value.name;
    if (arg == "oeReport") {
      if (modifiers.btn) {
        const baseType = ["string", "number", "boolean"];
        if (baseType.find((item) => item == typeof value)) value = [value];
        if (value == null || value == undefined) value = [];
        oeReport(OeReportType.Action, OeReportSubType.BtnClick, pageName, ...value);
      }
      if (modifiers.illegal) {
        const app_x = e.clientX,
          app_y = e.clientY;
        oeReport(OeReportType.Action, OeReportSubType.IllegalClick, pageName, value, undefined, app_x, app_y);
      }
      if (modifiers.action) {
        const baseType = ["string", "number", "boolean"];
        if (baseType.find((item) => item == typeof value)) value = [value];
        if (value == null || value == undefined) value = [];
        oeReport(OeReportType.Action, OeReportSubType.ActionCollect, pageName, ...value);
      }
    }
  };
};
const vClick = {
  created(el: any, binding: any) {
    const { arg, value, modifiers } = binding;
    el._click_hander = handleClick(arg, value, modifiers);
    el.addEventListener("click", el._click_hander);
  },
  beforeUnmount(el: any) {
    el.removeEventListener("click", el._click_hander);
  }
};

/** 动态添加子元素指令 */
const vAppendChild = {
  mounted(el: any, binding: any) {
    const { arg, value } = binding;
    const _el = document.createElement(arg);
    if (Object.prototype.toString.call(value) === "[object Object]") {
      Object.entries(value).forEach(([key, val]) => {
        _el.setAttribute(key, val);
      });
    }
    el.appendChild(_el);
  }
};

const setGlobalDirective = (app: any) => {
  app.directive("slide", vSlide);
  app.directive("click", vClick);
  app.directive("append-child", vAppendChild);
};

export function useDirective() {
  return { _first_inter_show, vSlide, vClick, vAppendChild, setGlobalDirective };
}
